
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    Breadcrumbs
  },

  data () {
    return {
      limit: 4,
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.compare.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      compare: 'compare/compare',
      currency: 'currency',
      logo: 'logo'
    }),

    perPage () {
      let perPage = (this.compare.items.length > this.limit ? this.limit : this.compare.items.length)

      if (!['mobileSmall', 'mobile'].includes(this.$mq)) {
        perPage = perPage + 1
      }

      return perPage
    },

    pricesDiffer () {
      return [...new Set(this.compare.items.map(item => item.price.current))].length > 1
    }
  },

  methods: {
    remove (item) {
      this.$store.dispatch('compare/removeCompare', { item })
    },

    characteristicValuesDiffer (characteristic) {
      return (
        characteristic.field === 'select' &&
        this.compare.items.map(item => (item.characteristics.find(c => c.id === characteristic.id) !== undefined ? item.characteristics.find(c => c.id === characteristic.id).values : [])).length > 0 &&
        this.compare.items.map(item => (item.characteristics.find(c => c.id === characteristic.id) !== undefined ? item.characteristics.find(c => c.id === characteristic.id).values : []).map(v => v.id).sort().toString()).filter((value, index, array) => array.indexOf(value) === index).length > 1
      ) ||
      (
        characteristic.field === 'input' &&
        [...new Set(this.compare.items.map(item => item.characteristics.find(c => c.id === characteristic.id) !== undefined ? item.characteristics.find(c => c.id === characteristic.id).value : ''))].length > 1
      )
    }
  },

  head () {
    return {
      title: this.$t('pages.compare.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.compare.description')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.compare.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.compare.description')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
